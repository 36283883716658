<template>
    <div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
        <div class="container p-3">
            <b-row class="m-0 justify-content-center shadow-2">
                <b-col md="6" class="p-0 bg-white">
                    <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                        <div class="full-width align-self-start">
                            <div class="full-width navbar-brand mb-4 mt-4">
                                <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                            </div>
                            <q-separator class="m-0" dark />
                        </div>
                        <div class="full-width pb-4 align-self-end text-white">
                            <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                            <q-btn icon-right="send" color="danger" square no-caps :to="{name: 'Register'}" type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('register') }}</q-btn>
                            <q-btn icon-right="login" color="dark" square no-caps :to="{name: 'Login'}" type="button" class="ml-1 no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                        </div>
                    </q-card>
                </b-col>
                <b-col md="6" class="p-0 bg-white">
                    <q-card no-body class="p-4 full-height register-form" light>
                        <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                            <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                            <switch-lang :language="$route.query['lang'] ? $route.query['lang'] : null"></switch-lang>
                        </AppHeader>
                        <h1 
                            class="text-h4 text-uppercase text-center font-weight-bold"
                            :class="msg === 'bad_activation_token' ? 'text-secondary' : 'text-primary'"
                        >
                            {{ translate('activate_account') }}
                        </h1>
                        <p class="text-center text-muted" v-if="msg !== 'bad_activation_token'">{{ translate('activation_here') }}</p>
                        <div class="text-center">
                            <template v-if="msg === 'bad_activation_token'">
                                <p class="text-center text-muted">{{ translate('already_activated') }}</p>
                            </template>
                            <template v-else>
                                <q-btn class="mt-2 icon-right-leftspace" icon-right="send" @click.prevent="activateUser()" color="success" square no-caps type="submit">{{ translate('activate') }}</q-btn>
                            </template>
                        </div>
                    </q-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import { eventBus } from '../../main'
    import {
        Header as AppHeader
    } from '@coreui/vue'
    import LangNavbarAuth from './LangNavbarAuth'
    import {
        required,
        email,
        minLength,
        sameAs
    } from "vuelidate/lib/validators"
    import _ from 'lodash'

    export default {
        name: 'ActivationCustomerAccount',
        components: {
            AppHeader,
            'switch-lang': LangNavbarAuth
        },
        computed: {
            random_background: function () {
                var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
                var random = this.$shuffle(array)

                return random[0]
            },
            container_background_image: function () {
                return 'background-image: url(' + this.random_background + ');'
            },
        },
        data: function () {
            return {
                msg: null,
            }
        },
        methods: {
            activateUser: function () {
                var params = {
                    email: this.$route.query['email'],
                    activation_token: this.$route.query['activation_token']
                }
                var self = this
                axios.post(baseUrl + 'auth/activate-customer-user', params)
                    .then(response => {
                        var message = 'activate_customer_account_' + response.data.msg
                        this.msg = response.data.msg === 'bad_activation_token' ? response.data.msg : message

                        if (response.data && response.data.msg === 'resource_activated') {
                            this.activated = true
                            self.$router.push({
                                name: 'CreatedAccount'
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
</script>
